<template>
  <div class="pageContol listWrap templateList">
    <div class="framePage">
      <div class="framePage-title">
        <span>
          <em>当前位置：</em>
          <a href="javascript:;">招聘模块</a>
          <i>></i>
          <a href="javascript:;" class="cur-a">人才库</a>
        </span>
      </div>
      <div class="framePage-body">
        <div class="operationControl flexdc" style="align-items: flex-start; ">
          <div class="searchbox" style="margin-bottom:15px">
            <div title="姓名" class="searchboxItem ci-full">
              <span class="itemLabel" style="min-width:3rem">姓名:</span>
              <el-input v-model="retrievalData.userName" type="text" size="small" clearable placeholder="请输入姓名" />
            </div>
            <div title="手机号" class="searchboxItem ci-full">
              <span class="itemLabel" style="min-width:4rem">手机号:</span>
              <el-input v-model="retrievalData.mobile" type="text" size="small" clearable placeholder="请输入手机号" />
            </div>
            <div title="人才等级" class="searchboxItem ci-full">
              <span class="itemLabel">人才等级:</span>
              <el-select clearable size="small" v-model="retrievalData.talentLevel" placeholder="请选择">
                <el-option v-for="item in talentLevelData" :key="item.value" :label="item.label"
                  :value="item.value"></el-option>
              </el-select>
            </div>
            <div title="推荐系数" class="searchboxItem ci-full">
              <span class="itemLabel">推荐系数:</span>
              <el-select clearable size="small" v-model="retrievalData.recommendLevel" placeholder="请选择">
                <el-option v-for="item in recommendLevelData" :key="item.value" :label="item.label"
                  :value="item.value"></el-option>
              </el-select>
            </div>
          </div>
          <div class="searchbox" style="margin-bottom:15px">
            <div title="创建人" class="searchboxItem ci-full">
              <span class="itemLabel" style="min-width:4rem">创建人:</span>
              <el-select clearable size="small" v-model="retrievalData.adminId" placeholder="请选择">
                <el-option v-for="item in createUserList" :key="item.adminId" :label="item.fullname"
                  :value="item.adminId">
                </el-option>
              </el-select>
            </div>
            <div title="创建时间" class="searchboxItem ci-full">
              <span class="itemLabel">创建时间:</span>
              <el-date-picker v-model="retrievalData.createTimeArr" size="small" type="daterange" range-separator="至"
                start-placeholder="开始日期" end-placeholder="结束日期" format="yyyy-MM-dd" value-format="yyyy-MM-dd" />
            </div>
            <div title="备忘" class="searchboxItem ci-full">
              <span class="itemLabel" style="min-width:3rem">备忘:</span>
              <el-input v-model="retrievalData.remark" type="text" size="small" clearable placeholder="请输入备忘" />
            </div>
          </div>
          <div class="searchbox">
            <div class="btnBox" style="margin-left:20px">
              <el-button style="margin-left:20px" class="bgc-bv" round @click="getData()">查询</el-button>
              <el-button class="bgc-bv" round @click="addEdit">新增人才</el-button>
              <el-button class="bgc-bv" round @click="Exportstudent">快速导入</el-button>
            </div>
          </div>
        </div>
        <div class="framePage-scroll">
          <div class="ovy-a">
            <el-table ref="multipleTable" :data="tableData" :height="tableHeight" size="small" tooltip-effect="dark"
              style="width: 100%" :header-cell-style="tableHeader" stripe>
              <el-table-column label="序号" align="center" type="index" width="100px" :index="indexMethod" />
              <el-table-column label="姓名" align="left" prop="userName" show-overflow-tooltip min-width="100" />
              <el-table-column label="手机号" align="left" prop="mobile" show-overflow-tooltip min-width="100" />
              <el-table-column label="性别" align="left" prop="sex" show-overflow-tooltip min-width="100">
                <template slot-scope="scope">
                  <span v-if="scope.row.sex == 1">男</span>
                  <span v-else-if="scope.row.sex == 2">女</span>
                  <span v-else>--</span>
                </template>
              </el-table-column>
              <el-table-column label="年龄" align="left" prop="age" show-overflow-tooltip min-width="100" />
              <el-table-column label="出生日期" align="left" prop="birthday" show-overflow-tooltip min-width="150">
                <template slot-scope="scope">
                  <span>{{ scope.row.birthday | momentDate }}</span>
                </template>
              </el-table-column>
              <el-table-column label="学历" align="left" prop="educationName" show-overflow-tooltip min-width="100" />
              <!-- <el-table-column
                label="所在城市"
                align="left"
                prop="currentAddress"
                show-overflow-tooltip
                min-width="200"
              />
              <el-table-column
                label="现工作单位"
                align="left"
                prop="workUnit"
                show-overflow-tooltip
                min-width="300"
              /> -->
              <el-table-column label="意向职位" align="left" prop="positionName" show-overflow-tooltip min-width="150" />
              <el-table-column label="人才等级" align="left" prop="talentLevel" show-overflow-tooltip min-width="150">
                <template slot-scope="scope">
                  <el-rate v-if="scope.row.talentLevel" v-model="scope.row.talentLevel" disabled></el-rate>
                </template>
              </el-table-column>
              <el-table-column label="推荐系数" align="left" prop="recommendLevel" show-overflow-tooltip min-width="150">
                <template slot-scope="scope">
                  <el-rate v-if="scope.row.recommendLevel" v-model="scope.row.recommendLevel" disabled></el-rate>
                </template>
              </el-table-column>
              <el-table-column label="备忘" align="left" prop="remark" show-overflow-tooltip min-width="200" />
              <el-table-column label="创建人" align="left" prop="createMan" show-overflow-tooltip min-width="120" />
              <el-table-column label="创建时间" align="left" prop="createTime" show-overflow-tooltip min-width="160" />
              <el-table-column label="操作" align="center" width="240px" fixed="right">
                <div slot-scope="scope">
                  <el-button type="text" style="padding:0px 5px" size="mini" @click="addEdit(scope.row)">编辑</el-button>
                  <el-button type="text" style="padding:0px 5px" size="mini"
                    @click="toTecommendedSettings(scope.row)">推荐设置</el-button>
                  <el-button type="text" style="padding:0px 5px" size="mini"
                    @click="toProjectExperience(scope.row)">项目经历</el-button>
                  <el-button type="text" style="padding:0px 5px" size="mini"
                    @click="toWorkExperience(scope.row)">工作经历</el-button>
                  <el-button type="text" style="padding:0px 5px" size="mini"
                    @click="toJobHuntingIntention(scope.row)">求职意向</el-button>
                  <el-button type="text" style="padding:0px 5px" size="mini"
                    @click="toEducationalExperience(scope.row)">教育经历</el-button>
                  <el-button type="text" style="padding:0px 5px" size="mini"
                    @click="toVocationalSkills(scope.row)">职业技能</el-button>
                  <el-button type="text" style="padding:0px 5px" size="mini"
                    @click="toNotesSettings(scope.row)">备忘</el-button>
                </div>
              </el-table-column>
              <Empty slot="empty" />
            </el-table>
          </div>
        </div>
        <PageNum :apiData="apiData" @sizeChange="sizeChange" @getData="getData" />
      </div>
    </div>
    <!--快速导入-->
    <el-dialog title="快速导入" :visible.sync="centerDialogVisible" width="50%" center :before-close="doCloseload"
      class="exportdialog">
      <div class="flexdcc">
        <div class="export-box">
          <h1>
            1
            <span>下载并填写导入模板</span>
          </h1>
          <div class="df">
            <span @click="downloadExcelgs" style="margin-top: 20px;color: #409EFF;cursor: pointer">
              人才导入模板.xls
            </span>
          </div>
        </div>
        <div class="export-box">
          <h1>
            2
            <span>导入编写好的Excel文档</span>
          </h1>
          <div class="flexdc">
            <div class="df" style="margin: 20px 0 10px">
              <el-upload class="upload-demo upload-workers" :action="actionUrl" :on-error="handleError"
                :on-success="handleSuccess" :on-change="uploadChange" :show-file-list="false" :auto-upload="false">
                <el-button class="bgc-bv" style="font-size: 12px;">浏览</el-button>
              </el-upload>
              <p v-if="fileName" style="margin-top: 10px; margin-left: 10px">
                当前选择文件：
                <span style="color: #f46173">{{ fileName }}</span>
              </p>
              <p v-else style="margin-top: 10px; margin-left: 10px">
                未选择文件
              </p>
            </div>
            <div>
              <el-button class="bgc-bv" size="mini" style="margin-top: 10px; height: 35px" :disabled="doExportInDisabled"
                @click="doExport">开始导入</el-button>
            </div>
            <div style="margin-top: 10px" v-if="progressVal > 0">
              <el-progress :text-inside="true" :stroke-width="20" :percentage="progressVal"></el-progress>
            </div>
          </div>
        </div>
        <div class="export-box">
          <h1>
            3
            <span>导入结果</span>
          </h1>
          <div class="df studentNum">
            <span>人才总数量:{{ totalNum }}人;</span>
            <span>成功:{{ correctNum }}人;</span>
            <span>失败:{{ errorNum }}人;</span>
          </div>
          <div>
            <el-button class="bgc-bv" style="margin-top: 15px; height: 35px" size="mini" :disabled="errorNum == '0'"
              @click="doExportError">导出错误数据</el-button>
          </div>
          <div style="margin-top: 15px; color: #dd1d35" v-if="errorNum != 0">
            有导入失败人才，请导出错误数据，将导入失败人才调整后重新上传
          </div>
        </div>
      </div>
    </el-dialog>
  </div>
</template>
<script>
import Empty from "@/components/Empty.vue";
import PageNum from "@/components/PageNum.vue";
import List from "@/mixins/List";
export default {
  name: "hr_recruitModule_personnelLibrary_personnelList",
  components: {
    Empty,
    PageNum
  },
  mixins: [List],
  doNotInit: true,
  data() {
    return {
      createUserList: [],
      // 检索数据
      retrievalData: {
        userName: "", // 姓名
        mobile: "", // 手机号
        talentLevel: "", // 人才等级
        recommendLevel: "", // 推荐系数
        adminId: "",     // 创建人
        createTimeArr: [], //创建时间
        remark:"", // 备忘
      },
      // 人才等级 - 拉下数据
      talentLevelData: [
        {
          label: "一星",
          value: "1"
        },
        {
          label: "二星",
          value: "2"
        },
        {
          label: "三星",
          value: "3"
        },
        {
          label: "四星",
          value: "4"
        },
        {
          label: "五星",
          value: "5"
        }
      ],
      // 推荐系数 - 下拉数据
      recommendLevelData: [
        {
          label: "一星",
          value: "1"
        },
        {
          label: "二星",
          value: "2"
        },
        {
          label: "三星",
          value: "3"
        },
        {
          label: "四星",
          value: "4"
        },
        {
          label: "五星",
          value: "5"
        }
      ],

      // 快速导入
      centerDialogVisible: false,
      // newvalues: [], // 存储value的数组
      // Course: [], //
      totalNum: "0",
      errorNum: "0",
      correctNum: "0",
      batchId: "",
      importType: "",
      fileName: "",
      fileKey: "",
      excelUrl: "",


      currentPage: ''
    };
  },
  computed: {},
  created() {
    this.getCreateMan();
    if (this.$route.query.retrievalData && this.$route.query.retrievalData.userName) {
      this.retrievalData.userName = this.$route.query.retrievalData.userName
    }
    if (this.$route.query.retrievalData && this.$route.query.retrievalData.mobile) {
      this.retrievalData.mobile = this.$route.query.retrievalData.mobile
    }
    if (this.$route.query.retrievalData && this.$route.query.retrievalData.talentLevel) {
      this.retrievalData.talentLevel = this.$route.query.retrievalData.talentLevel
    }
    if (this.$route.query.retrievalData && this.$route.query.retrievalData.recommendLevel) {
      this.retrievalData.recommendLevel = this.$route.query.retrievalData.recommendLevel
    }
    console.log(this.$route.query.currentPage)
    if (this.$route.query.currentPage && this.$route.query.currentPage > 1) {
      this.getData(this.$route.query.currentPage)
    } else if (this.$route.query.currentPage == '1') {
      this.getData()
    } else {
      this.getData()
    }
  },
  methods: {
    //获取可用创建人列表
    getCreateMan() {
      this.$post("/hr/talent/info/create/user")
        .then((res) => {
          this.createUserList = res.data || [];
        })
        .catch(() => {
          return;
        });
    },
    // 获取列表数据
    getData(pageNum = 1) {
      const params = {
        pageNum: pageNum,
        pageSize: this.pageSize
      };
      this.currentPage = pageNum
      if (this.retrievalData.userName) {
        params.userName = this.retrievalData.userName;
      }
      if (this.retrievalData.mobile) {
        params.mobile = this.retrievalData.mobile;
      }
      if (this.retrievalData.talentLevel) {
        params.talentLevel = this.retrievalData.talentLevel;
      }
      if (this.retrievalData.recommendLevel) {
        params.recommendLevel = this.retrievalData.recommendLevel;
      }
      if (this.retrievalData.adminId) {
        params.adminId = this.retrievalData.adminId;
      }
      if (this.retrievalData.createTimeArr && this.retrievalData.createTimeArr.length == 2) {
        params.createDateByStart = this.retrievalData.createTimeArr[0]
        params.createDateByEnd = this.retrievalData.createTimeArr[1]
      }
      if (this.retrievalData.remark) {
        params.remark = this.retrievalData.remark;
      }
      this.doFetch({
        url: "/hr/talent/info/pageList",
        params,
        pageNum
      });
    },
    // 新增人才 || 编辑人才
    addEdit(row) {
      this.$router.push({
        path: "/hr/recruitModule/personnelLibrary/addPersonnel",
        query: {
          talentId: row.talentId || '',
          retrievalData: this.retrievalData,
          currentPage: this.currentPage
        },
      });
    },
    // 去往 - 推荐设置
    toTecommendedSettings(row) {
      this.$router.push({
        path: "/hr/recruitModule/personnelLibrary/recommendedSettings",
        query: {
          talentId: row.talentId,
          retrievalData: this.retrievalData,
          currentPage: this.currentPage
        },
      });
    },
    // 去往 - 项目经历
    toProjectExperience(row) {
      this.$router.push({
        path: "/hr/recruitModule/personnelLibrary/projectExperience",
        query: {
          talentId: row.talentId || '',
          userName: row.userName || '',
          retrievalData: this.retrievalData,
          currentPage: this.currentPage
        },
      });
    },
    // 去往 - 工作经历
    toWorkExperience(row) {
      this.$router.push({
        path: "/hr/recruitModule/personnelLibrary/workExperience",
        query: {
          talentId: row.talentId || '',
          userName: row.userName || '',
          retrievalData: this.retrievalData,
          currentPage: this.currentPage
        },
      });
    },
    // 去往 - 备忘
    toNotesSettings(row) {
      this.$router.push({
        path: "/hr/recruitModule/personnelLibrary/notesSettings",
        query: {
          talentId: row.talentId || '',
          retrievalData: this.retrievalData,
          currentPage: this.currentPage
        },
      });
    },
    // 去往 - 求职意向
    toJobHuntingIntention(row) {
      this.$router.push({
        path: "/hr/recruitModule/personnelLibrary/jobHuntingIntention",
        query: {
          talentId: row.talentId || '',
          userName: row.userName || '',
          retrievalData: this.retrievalData,
          currentPage: this.currentPage
        },
      });
    },
    // 去往 - 教育经历
    toEducationalExperience(row) {
      this.$router.push({
        path: "/hr/recruitModule/personnelLibrary/educationalExperience",
        query: {
          talentId: row.talentId || '',
          retrievalData: this.retrievalData,
          currentPage: this.currentPage
        }
      })
    },
    // 去往 -- 职业技能
    toVocationalSkills(row) {
      this.$router.push({
        path: "/hr/recruitModule/personnelLibrary/vocationalSkills",
        query: {
          talentId: row.talentId || '',
          userName: row.userName || '',
          retrievalData: this.retrievalData,
          currentPage: this.currentPage
        },
      });
    },
    // 导入
    Exportstudent() {
      this.totalNum = "0";
      this.errorNum = "0";
      this.correctNum = "0";
      this.batchId = "";
      this.centerDialogVisible = true;
    },
    // 关闭快速导入
    doCloseload() {
      this.centerDialogVisible = false;
      this.fileKey = "";
      this.fileName = "";
      this.progressVal = 0;
      this.doExportInDisabled = false;
      this.getData();
    },
    // 下载模板
    downloadExcelgs(templatePath) {
      let link = document.createElement("a");
      link.style.display = "none";
      link.href = '/static/talent.xls';
      let templateNames = '人才导入模板.xls';
      link.setAttribute("download", templateNames);
      document.body.appendChild(link);
      link.click();
    },
    // 导入人才
    uploadChange(file) {
      this.fileName = file.name;
      let size = file.size / 1024 / 1024;
      // let fileName = file.name.split(".");
      let extension = file.name.substring(file.name.lastIndexOf(".") + 1);
      const isXLSX = extension === "XLSX";
      const isxlsx = extension === "xlsx";
      const isXLS = extension === "XLS";
      const isxls = extension === "xls";
      if (!isXLSX && !isxlsx && !isXLS && !isxls) {
        this.$message.error("只能上传后缀是.xlsx或者.xls的文件");
        return;
      }
      if (size > 3) {
        this.$message.error("文件大小不能超过3M");
        return;
      }
      let formData = new FormData();
      formData.append("folder ", "USER");
      formData.append("file ", file.raw);
      formData.append("fileType ", extension);

      this.$Postformat("/sys/upload", formData)
        .then((result) => {
          this.fileKey = result.data.fileKey;
        })
        .catch((err) => {
          return;
        });
    },
    // 导入
    doExport() {
      if (this.fileKey == "") {
        this.$message({
          message: "请选择文件",
          type: "warning",
        });
      } else {
        this.doExportInDisabled = true;
        if (this.fileKey) {
          const parmar = {
            excelUrl: this.fileKey,
          };
          this.$post("/hr/talent/info/importTalent", parmar, 5000)
            .then((res) => {
              if (res.status == 0) {
                if (res.status == 0) {
                  console.log(res.message);
                  this.doProgress(res.message);
                }
              }
            })
            .catch(() => {
              return;
            });
        } else {
          this.$message({
            message: "请选择文件",
            type: "warning",
          });
        }
      }
    },
    // 导出错误数据
    doExportError() {
      // console.log(this.batchId);
      if (this.batchId == "" || this.fileKey == "") {
        this.$message({
          message: "还没有导入数据，请先导入文档",
          type: "warning",
        });
      } else {
        this.$post(
          "/hr/talent/info/exportErrorTalent",
          { batchId: this.batchId },
          5000
        ).then((res) => {
          if (res.status == 0) {
            window.open(res.data);
          }
        });
      }
    },
    // 获取导入进度
    doProgress(batchId) {
      this.$post(
        "/hr/talent/info/importProgress",
        { batchId },
        5000
      ).then((ret) => {
        if (!ret.data.status) {
          this.progressVal = ret.data.progress;
          setTimeout(() => {
            this.doProgress(batchId);
          }, 2000);
        } else {
          if (ret.data.message) {
            this.$message({
              type: "error",
              message: ret.data.message,
            });
          } else {
            this.totalNum = ret.data.totalNum;
            this.errorNum = ret.data.errorNum;
            this.correctNum = ret.data.correctNum;
            this.importType = ret.data.importType;
            this.batchId = ret.data.batchId;
            this.progressVal = ret.data.progress;
            this.$message({
              type: "success",
              message: "导入成功",
            });
          }
        }
      });
    },
  },
  watch: {

  }
};
</script>
<style lang="less" scoped>
.exportdialog {
  .upload-workers {
    width: 80px;
    height: 35px;

    .el-upload {
      width: 100% !important;
      height: 35px !important;
      border: none !important;

      .el-button {
        width: 100% !important;
        height: 32px !important;
        padding: 5px 0;
      }
    }
  }

  .el-dialog__title {
    font-size: 16px;
  }

  .export-box {
    width: 100%;
    margin-bottom: 20px;

    h1 {
      padding: 0 5px 10px;
      display: flex;
      align-items: flex-end;
      font-size: 40px;
      border-bottom: 1px dashed #ccc;

      span {
        margin-left: 20px;
        font-size: 14px;
      }
    }

    >div {
      padding-left: 30px;
    }

    .el-button.is-disabled,
    .el-button.is-disabled:focus,
    .el-button.is-disabled:hover {
      color: #fff;
      background: #c3c3c3;
      border: none;
    }
  }
}

.docsDialog {
  .el-form-item {
    margin-bottom: 0.5rem;
  }
}

.studentNum {
  margin: 20px 0 10px;

  span {
    margin: 0 10px;
  }
}
</style>
<style>
.el-tooltip__popper {
  max-width: 30%;
}
</style>
